$(document).ready(function () {

    //Option to reset a form row to its original value.
    if ($(".pp_reset_formfield").length > 0) {
        $(document).on("change", "input", function () {
            var oldvalue = $(this).data("value");
            var newvalue = $(this).val();
            if (newvalue !== oldvalue) {
                if ($("#pp-form-reset").length > 0) {
                    $("#pp-form-reset").fadeIn();
                }
                $(this).parent().find(".pp_reset_formfield").fadeIn();
            } else {
                $(this).parent().find(".pp_reset_formfield").fadeOut();
            }
        });
        $(document).on("click", ".pp_reset_formfield", function () {
            var input = $(this).parent().find("input");
            var oldvalue = input.data("value");
            var newvalue = input.val();
            if (newvalue !== oldvalue) {
                input.val(oldvalue);
                $(this).fadeOut();
            }
        });
    }

    //Disable enter key for forms:
    $(document).on("keydown", ".disable-form-enter", function (event) {
        return event.key !== "Enter";
    });

    //Filemanager stand-alone button:
    if ($('.lfm_stand_alone').length > 0) {
        var route_prefix = "/filemanager";
        $('.lfm_stand_alone').each(function () {
            var disk = $(this).data("disk");
            $(this).filemanager(disk, { prefix: route_prefix });
        });
    }

    if ($(".loading").length > 0) {
        $(document).on("click", ".jq_loading", function () {
            $(".loading").fadeIn("fast");
        });
    }

    //Change a default selection for the rights.
    //0 => 'Disallow everything', 1 => 'Page + Index', 2 => 'Page + Index + Edit', 3 => 'Page + Index + Edit + Create', 4 => 'Allow all'
    if ($('.jq_rights_onchange').length > 0) {
        $(document).on("change", ".jq_onchange_for_rights select", function () {
            var selected = $(".jq_onchange_for_rights select").children("option:selected").val();
            $('.jq_rights_onchange input[type="checkbox"]').prop("checked", false);
            if (selected === '1') {
                $('.jq_rights_index input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_closure input[type="checkbox"]').prop("checked", true);
            } else if (selected === '2') {
                $('.jq_rights_index input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_closure input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_edit input[type="checkbox"]').prop("checked", true);
            } else if (selected === '3') {
                $('.jq_rights_index input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_closure input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_edit input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_create input[type="checkbox"]').prop("checked", true);
            } else if (selected === '4') {
                $('.jq_rights_index input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_closure input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_edit input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_create input[type="checkbox"]').prop("checked", true);
                $('.jq_rights_destroy input[type="checkbox"]').prop("checked", true);
            }
        });
    }

});