$(document).ready(function () {

    //Ajax failure:
    function ajaxFailure(errorCode, errorMessage) {
        if ($(".loading").length > 0) {
            $(".loading").fadeOut("fast");
        }
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: errorCode + ': ' + errorMessage,
            showConfirmButton: false
        });
    }

    // Update an invoice:
    $(document).on("change", ".document-table input, .document-table select", function () {
        if ($(".loading").length > 0) {
            $(".loading").fadeIn("fast");
        }
        var module = $(".document-table").data('module');
        var csrf = $("input[name=_token]").val();
        var form = $(".document-table :input, input[name=_token]").serializeArray();
        $.ajax({
            type: 'POST', headers: { 'X-CSRF-TOKEN': csrf }, url: document.location.origin + '/ajax/update-document/' + module, cache: false, data: form,
            success: function (data) {
                $(".document-table").hide().html(data).fadeIn();
                $(":input").inputmask();
                if ($(".js-example-basic-single").length) {
                    $(".js-example-basic-single").select2();
                }
                if ($(".loading").length > 0) {
                    $(".loading").fadeOut("fast");
                }
            },
            error: function (XMLHttpRequest) {
                ajaxFailure(XMLHttpRequest.status, XMLHttpRequest.responseJSON.message);
            }
        });
    });

});