if ($('.jq_data_table_default').length > 0) {
    $(function () {
        'use strict';

        $(function () {
            $('.jq_data_table_default').DataTable({
                "order": [[0, 'desc']],
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 30,
                "language": {
                    search: ""
                }
            });
            $('.jq_data_table_default').each(function () {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        });

    });
}
if ($('.table-responsive').length > 0) {
    $('.table-responsive').delay(500).fadeIn(400);
}