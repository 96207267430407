$(function () {

    /**
     * This alert can be used in the onclick of a HTML element.
     * 
     * @param string type
     * @param string title
     * @param string text
     * @param string redirect
     * 
     * @return popup
     */

    paperplaneAlert = function (type, title, text, redirect) {
        'use strict';
        if (type === 'delete') {
            swal.fire({
                title: title,
                text: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete',
                cancelButtonText: 'No, cancel'
            }).then((result) => {
                if (("value" in result)) {
                    window.location.href = redirect;
                }
            });
        } else if (type === 'delete_controller_row') {
            swal.fire({
                title: title,
                text: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete',
                cancelButtonText: 'No, cancel'
            }).then((result) => {
                if (("value" in result)) {
                    $("#" + redirect).submit();
                }
            });
        } else if (type === 'saved') {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: title,
                showConfirmButton: false,
                timer: 1500
            });
        } else if (type === 'warning') {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: title,
                showConfirmButton: false,
                timer: 2500
            });
        } else if (type === 'error') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: title,
                showConfirmButton: false,
                timer: 2500
            });
        }
    };

});


$(function () {

    /**
     * This can be used to confirm changes inside an edit controller action.
     * Put this on the onclick of the submit button to trigger. It will only trigger
     * if a hidden input pp-form-confirmation is found with the field and the fields are changed.
     * 
     * @return popup
     */

    paperplaneConfirmChanges = function (buttonid) {
        if ($('#pp-form-confirmation').length > 0) {
            var popup = false;
            var changingFields = "";
            var confirm = JSON.parse($('#pp-form-confirmation').val());
            $.each(confirm, function (i, item) {
                var label = $("#" + item + '_label').html().replace("*", "");
                var oldvalue = $("#" + item).data("value");
                var newvalue = $("#" + item).val();
                console.log(oldvalue, newvalue);
                if (oldvalue !== newvalue) {
                    popup = true;
                    if (oldvalue === '') {
                        oldvalue = 'empty';
                    }
                    changingFields = changingFields + "<tr style='border-top: 1px solid #303d5f;'><td style='padding: 5px 0; color: #8991f5 !important;'>" + label + '</td><td style="color: #ff3366;">' + oldvalue + '</td><td style="color: #10b759;">' + newvalue + '</td></tr>';
                }
            });
            if (popup) {
                //Something has changed which we need to double check:
                var table = '<table style="width: 100%;"><tr><th>Column</th><th>Old value</th><th>New value</th></tr>' + changingFields + '</table>';
                swal.fire({
                    title: 'Confirmation needed',
                    html: 'You are about to change something which needs a double check. Please check the following data:<br /><br /><br />' + table + '</br >',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Confirmed, change it',
                    cancelButtonText: 'No, cancel'
                }).then((result) => {
                    if (("value" in result)) {
                        //Confirmation is given, submit the form:
                        $('#' + buttonid + '_submit').click();
                    }
                });
            } else {
                //Nothing changed which we need to check, just proceed:
                $('#' + buttonid + '_submit').click();
            }
        } else {
            //When the confirmation field is not present, just submit:
            $('#' + buttonid + '_submit').click();
        }
    };

});



$(function () {

    /**
     * Use this function to reset all input fields to their original value.
     */

    paperplaneResetForm = function () {
        if ($('form .form-group input').length > 0) {
            $(".pp_reset_formfield").fadeOut();
            $('form .form-group input').each(function (i) {
                var oldvalue = $(this).data("value");
                var newvalue = $(this).val();
                if (newvalue !== oldvalue) {
                    $(this).val(oldvalue);
                }
            });
            $("#pp-form-reset").fadeOut();
        }
    };

});

$(function () {

    /**
     * This can be used to confirm changes inside an edit controller action.
     * 
     * @param string table
     * @param integer id
     * 
     * @return popup
     */

    paperplaneConfirmDuplicate = function (url, table, id, primarykey) {
        $(this).data();
        swal.fire({
            title: 'Duplicate?',
            text: 'Are you sure? When created you will be redirected.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, duplicate',
            cancelButtonText: 'No, cancel'
        }).then((result) => {
            if (("value" in result)) {
                $.ajax({ url: "/ajax/action/duplicate/" + table + "/" + id + "/" + primarykey }).done(function (newid) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Duplicated to ' + newid,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    window.setTimeout(function () { window.location.href = url + '/' + newid + '/edit' }, 1000);
                });
            }
        });
    };

});